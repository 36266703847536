import moment from "moment";
moment.updateLocale("ja", {
  months: ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月",],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

//ヘッダー列
export const headerColKeys = [
  'no',

  "centerCD", //"倉庫"
  "centerNM", //"倉庫"
  "itemCD", //"商品CD"
];
//行ヘッダー列
export const rowHeaderColKeys = [
  // 'rowHeader', 
];
//ヘッダー列
export const bodyColKeys = [
  "tanaban", //"棚番"
  "janCD", //"JAN"
  "bestBefore", //"賞味期間"
  "tolerance", //"許容期間"
  "makerCD", //"メーカーCD"
  "makerNM", //"メーカーNM"
  "itemNM", //"商品名"

  "bumonCD", //"部門"
  "bumonNM", //"部門"
  "category", //"商品カテゴリ"
  "pbKbnCD",
  "pbKbnNM",
  "irisu", //"BL入数"
  "blIrisu", //"BL入数"
  "csIrisu", //"CS入数"
  "capacity", //"内容量"
  "teika", //"定価"
  "tatene", //"建値"

  "teibanNum",
  "teibanNMs",
  'nohinbi',	    //最終納品日
  'tokuisakiNum', //直近1カ月出荷得意先数
  'tokuisakiNMs', //直近1カ月出荷得意先名（カンマ区切り複数）

  "zaikoKbn",
  "zaikoTotalBl",
  "zaikoTotalPs",
  "nyukaDate",
  "keikaDays",
  "zaikoQtyBl",
  "zaikoQtyPs",
  "zaikoQtyPsTotal",
  "zaikoAmtPsTotal",
  "nohinAbleDateTokui",
  "kigenDateTokui",
  "nohinAbleDate2_3",
  "kigenDate2_3",
  "nohinAbleDate1_2",
  "kigenDate1_2",
  "check",
  "kigenDateReal",
  "zaikoQtyBlReal",

];

export const rowKeys:string[] = [
  'kigen',
];

export const headersCol = [
  {
    no : "No.",

    bumonCD: "部門",
    bumonNM: "部門",
    centerCD: "倉庫",
    centerNM: "倉庫",
    makerCD: "メーカー",
    makerNM: "メーカー",
    itemCD: "商品",
    itemNM: "商品属性",
    janCD: "商品属性",
    category: "商品属性",
    pbKbnCD: "PB区分",
    pbKbnNM: "PB区分",
    irisu: "入数",
    blIrisu: "入数",
    csIrisu: "入数",
    capacity: "商品属性",
    bestBefore: "商品属性",
    tolerance: "商品属性",
    teika: "商品属性",
    tatene: "商品属性",
    tanaban: "棚番",


    rowHeader: "項目", 

    teibanNum: '定番',
    teibanNMs: '定番',
    nohinbi: "最終",
    tokuisakiNum: '直近1カ月出荷',
    tokuisakiNMs: '直近1カ月出荷',

    zaikoKbn: "在庫",
    zaikoTotalBl: "在庫",
    zaikoTotalPs: "在庫",
    nyukaDate: "入荷日別在庫",
    keikaDays: "入荷日別在庫",
    zaikoQtyBl: "入荷日別在庫",
    zaikoQtyPs: "入荷日別在庫",
    zaikoQtyPsTotal: "入荷日別在庫",
    zaikoAmtPsTotal: "入荷日別在庫",
    nohinAbleDateTokui: "得意先許容計算",
    kigenDateTokui: "得意先許容計算",
    nohinAbleDate2_3: "１／３許容計算",
    kigenDate2_3: "１／３許容計算",
    nohinAbleDate1_2: "１／２許容計算",
    kigenDate1_2: "１／２許容計算",
    check: "チェック",
    kigenDateReal: "実在庫記入欄（鮮度チェック）",
    zaikoQtyBlReal: "実在庫記入欄（鮮度チェック）",

    
  },
  {
    no : "",

    bumonCD: "CD",
    bumonNM: "名称",
    centerCD: "CD",
    centerNM: "名称",
    itemCD: "商品CD",
    itemNM: "商品名",
    janCD: "JAN",
    makerCD: "CD",
    makerNM: "名称",
    category: "商品カテゴリ",
    pbKbnCD: "CD",
    pbKbnNM: "名称",
    irisu: "入数",
    blIrisu: "BL入数",
    csIrisu: "CS入数",
    capacity: "内容量",
    bestBefore: "賞味期間(日)",
    tolerance: "得意先許容日数",
    teika: "定価(円)",
    tatene: "建値(円)",
    tanaban: "棚番",

    rowHeader : "名称", 

    teibanNum: '得意先数',
    teibanNMs: '得意先名',
    nohinbi: "納品日",
    tokuisakiNum: '得意先数',
    tokuisakiNMs: '得意先名',

    zaikoKbn: "在庫区分",
    zaikoTotalBl: "総在庫BL数",
    zaikoTotalPs: "総在庫バラ数",
    nyukaDate: "入荷日",
    keikaDays: "経過日数",
    zaikoQtyBl: "BL数",
    zaikoQtyPs: "バラ数",
    zaikoQtyPsTotal: "バラ総数",
    zaikoAmtPsTotal: "在庫金額",
    nohinAbleDateTokui: "納品可能",
    kigenDateTokui: "賞味期限",
    nohinAbleDate2_3: "納品可能",
    kigenDate2_3: "賞味期限",
    nohinAbleDate1_2: "納品可能",
    kigenDate1_2: "賞味期限",
    check: "チェック",
    kigenDateReal: "賞味期限",
    zaikoQtyBlReal: "BL数",

  },
];

export const headersRow = {
  kigen: '賞味期限',
};

export const zaikoKbnMixList = {
  all: '合計',
  1: '一般/良品',	//在庫1(一般)
  2: '特売',	//在庫2(特売)
  3: '予約',	//在庫3(予約)
  4: '出切',	//在庫4(出切)
  5: '返品',	//在庫5(返品)
};
export const zaikoKbnList = {
  all: '合計',
  1: '一般',	//在庫1(一般)
  2: '特売',	//在庫2(特売)
  3: '予約',	//在庫3(予約)
  4: '出切',	//在庫4(出切)
  5: '返品',	//在庫5(返品)
};
export const zaikoKbn0602List = {
  all: '合計',
  1: '良品',	//在庫1(一般)
  4: '出切',	//在庫4(出切)
  5: '返品',	//在庫5(返品)
};

export const selectableCol =
{
  no : false,

  bumonCD: true,
  bumonNM: true,
  centerCD: true,
  centerNM: true,
  itemCD: true,
  itemNM: true,
  janCD: true,
  makerCD: true,
  makerNM: true,
  category: true,
  pbKbnCD: true,
  pbKbnNM: true,
  irisu: true,
  blIrisu: true,
  csIrisu: true,
  capacity: true,
  bestBefore: true,
  tolerance: true,
  teika: true,
  tatene: true,
  tanaban: true,

  rowHeader : false, 

  teibanNum: true,
  teibanNMs: true,
  nohinbi: true, 
  tokuisakiNum: true,
  tokuisakiNMs: true,

  zaikoKbn: true,
  zaikoTotalBl: true,
  zaikoTotalPs: true,
  nyukaDate: true,
  keikaDays: true,
  zaikoQtyBl: true,
  zaikoQtyPs: true,
  zaikoQtyPsTotal: true,
  zaikoAmtPsTotal: true,
  nohinAbleDateTokui: true,
  kigenDateTokui: true,
  nohinAbleDate2_3: true,
  kigenDate2_3: true,
  nohinAbleDate1_2: true,
  kigenDate1_2: true,
  
  check: false,
  kigenDateReal: false,
  zaikoQtyBlReal: false,
}
export const selectableRow = {
  kigen: false,
};



export const headerColClasses = [
  {
    no : "zaikokigen_H",

    bumonCD: "zaikokigen_H",
    bumonNM: "zaikokigen_H",
    centerCD: "zaikokigen_H",
    centerNM: "zaikokigen_H",
    itemCD: "zaikokigen_H",
    itemNM: "zaikokigen_H",
    janCD: "zaikokigen_H",
    makerCD: "zaikokigen_H",
    makerNM: "zaikokigen_H",
    category: "zaikokigen_H",
    pbKbnCD: "zaikokigen_H",
    pbKbnNM: "zaikokigen_H",
    irisu: "zaikokigen_H",
    blIrisu: "zaikokigen_H",
    csIrisu: "zaikokigen_H",
    capacity: "zaikokigen_H",
    bestBefore: "zaikokigen_H",
    tolerance: "zaikokigen_H",
    teika: "zaikokigen_H",
    tatene: "zaikokigen_H",
    tanaban: "zaikokigen_H",

    rowHeader : "zaikokigen_H",

    teibanNum: 'zaikokigen_T',
    teibanNMs: 'zaikokigen_T',

    nohinbi: "zaikokigen_H_N_noBorder",  //下線なし
    tokuisakiNum: 'zaikokigen_H_N',
    tokuisakiNMs: 'zaikokigen_H_N',

    zaikoKbn: "zaikokigen_H_Z",
    zaikoTotalBl: "zaikokigen_H_Z",
    zaikoTotalPs: "zaikokigen_H_Z",
    nyukaDate: "zaikokigen_H_Z",
    keikaDays: "zaikokigen_H_Z",
    zaikoQtyBl: "zaikokigen_H_Z",
    zaikoQtyPs: "zaikokigen_H_Z",
    zaikoQtyPsTotal: "zaikokigen_H_Z",
    zaikoAmtPsTotal: "zaikokigen_H_Z",
    nohinAbleDateTokui: "zaikokigen_H_Z",
    kigenDateTokui: "zaikokigen_H_Z",
    nohinAbleDate2_3: "zaikokigen_H_Z",
    kigenDate2_3: "zaikokigen_H_Z",
    nohinAbleDate1_2: "zaikokigen_H_Z",
    kigenDate1_2: "zaikokigen_H_Z",
    check: "zaikokigen_H",
    kigenDateReal: "zaikokigen_H",
    zaikoQtyBlReal: "zaikokigen_H",
  },
  {
    no : "zaikokigen_H",

    bumonCD: "zaikokigen_H",
    bumonNM: "zaikokigen_H",
    centerCD: "zaikokigen_H",
    centerNM: "zaikokigen_H",
    itemCD: "zaikokigen_H",
    itemNM: "zaikokigen_H",
    janCD: "zaikokigen_H",
    makerCD: "zaikokigen_H",
    makerNM: "zaikokigen_H",
    category: "zaikokigen_H",
    pbKbnCD: "zaikokigen_H",
    pbKbnNM: "zaikokigen_H",
    irisu: "zaikokigen_H",
    blIrisu: "zaikokigen_H",
    csIrisu: "zaikokigen_H",
    capacity: "zaikokigen_H",
    bestBefore: "zaikokigen_H",
    tolerance: "zaikokigen_H",
    teika: "zaikokigen_H",
    tatene: "zaikokigen_H",
    tanaban: "zaikokigen_H",

    rowHeader : "zaikokigen_H", 

    teibanNum: 'zaikokigen_T',
    teibanNMs: 'zaikokigen_T',

    nohinbi: "zaikokigen_H_N",
    tokuisakiNum: 'zaikokigen_H_N',
    tokuisakiNMs: 'zaikokigen_H_N',

    zaikoKbn: "zaikokigen_H_Z",
    zaikoTotalBl: "zaikokigen_H_Z",
    zaikoTotalPs: "zaikokigen_H_Z",
    nyukaDate: "zaikokigen_H_Z",
    keikaDays: "zaikokigen_H_Z",
    zaikoQtyBl: "zaikokigen_H_Z",
    zaikoQtyPs: "zaikokigen_H_Z",
    zaikoQtyPsTotal: "zaikokigen_H_Z",
    zaikoAmtPsTotal: "zaikokigen_H_Z",
    nohinAbleDateTokui: "zaikokigen_H_Z",
    kigenDateTokui: "zaikokigen_H_Z",
    nohinAbleDate2_3: "zaikokigen_H_Z",
    kigenDate2_3: "zaikokigen_H_Z",
    nohinAbleDate1_2: "zaikokigen_H_Z",
    kigenDate1_2: "zaikokigen_H_Z",
    check: "zaikokigen_H",
    kigenDateReal: "zaikokigen_H",
    zaikoQtyBlReal: "zaikokigen_H",
  },
];
export const headerRowClasses = {
  kigen: 'zaikokigen_RH',
};

export const colWidths =
{
  no : 50,

  bumonCD: 40,
  bumonNM: 80,
  centerCD: 50,
  centerNM: 100,
  itemCD: 100,
  itemNM: 150,
  janCD: 120,
  makerCD: 60,
  makerNM: 100,
  category: 100,
  pbKbnCD: 40,
  pbKbnNM: 100,
  irisu: 60,
  blIrisu: 50,
  csIrisu: 50,
  capacity: 50,
  bestBefore: 80,
  tolerance: 80,
  teika: 50,
  tatene: 70,
  tanaban: 80,

  rowHeader : 100, 

  teibanNum: 80,
  teibanNMs: 240,
  nohinbi: 80,
  tokuisakiNum: 80,
  tokuisakiNMs: 240,

  zaikoKbn: 50,
  zaikoTotalBl: 80,
  zaikoTotalPs: 80,
  nyukaDate: 80,
  keikaDays: 50,
  zaikoQtyBl: 70,
  zaikoQtyPs: 60,
  zaikoQtyPsTotal: 70,
  zaikoAmtPsTotal: 100,
  nohinAbleDateTokui: 80,
  kigenDateTokui: 80,
  nohinAbleDate2_3: 80,
  kigenDate2_3: 80,
  nohinAbleDate1_2: 80,
  kigenDate1_2: 80,
  check: 130,
  kigenDateReal: 100,
  zaikoQtyBlReal: 80,
};

export const colDataType =
{
  no : {type: 'text', readOnly: true,},

  bumonCD: {type: 'text', readOnly: true, className: 'htCenter',},
  bumonNM: {type: 'text', readOnly: true,},
  centerCD: {type: 'text', readOnly: true, className: 'htCenter',},
  centerNM: {type: 'text', readOnly: true,},
  itemCD: {type: 'text', readOnly: true, className: 'htCenter',},
  itemNM: {type: 'text', readOnly: true,},
  janCD: {type: 'text', readOnly: true, className: 'htCenter',},
  makerCD: {type: 'text', readOnly: true, className: 'htCenter',},
  makerNM: {type: 'text', readOnly: true,},
  category: {type: 'text', readOnly: true,},
  pbKbnCD: { type: 'text', readOnly: true, },
  pbKbnNM: { type: 'text', readOnly: true, },
  irisu: {type: 'text', readOnly: true,},
  blIrisu: {type: 'text', readOnly: true, className: 'htRight',},
  csIrisu: {type: 'text', readOnly: true, className: 'htRight',},
  capacity: {type: 'text', readOnly: true, className: 'htRight',},
  bestBefore: {type: 'text', readOnly: true, className: 'htRight',},
  tolerance: {type: 'text', readOnly: true, className: 'htRight',},
  teika: {type: 'text', readOnly: true, className: 'htRight',},
  tatene: {type: 'text', readOnly: true, className: 'htRight',},
  tanaban: {type: 'text', readOnly: true,},


  rowHeader: {type: 'text', readOnly: true,}, 

  teibanNum: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  teibanNMs: { type: 'text', readOnly: true, },
  nohinbi: {type: 'text', readOnly: true, className: 'htCenter',}, 
  tokuisakiNum: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  tokuisakiNMs: { type: 'text', readOnly: true, },

  zaikoKbn: {type: 'text', readOnly: true, className: 'htCenter',}, 
  zaikoTotalBl: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  zaikoTotalPs: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  nyukaDate: {type: 'text', readOnly: true, className: 'htCenter',}, 
  keikaDays: {type: 'text', readOnly: true, className: 'htRight',}, 
  zaikoQtyBl: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  zaikoQtyPs: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  zaikoQtyPsTotal: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  zaikoAmtPsTotal: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  nohinAbleDateTokui: {type: 'text', readOnly: true, className: 'htCenter',}, 
  kigenDateTokui: {type: 'text', readOnly: true, className: 'htCenter',}, 
  nohinAbleDate2_3: {type: 'text', readOnly: true, className: 'htCenter',}, 
  kigenDate2_3: {type: 'text', readOnly: true, className: 'htCenter',}, 
  nohinAbleDate1_2: {type: 'text', readOnly: true, className: 'htCenter',}, 
  kigenDate1_2: {type: 'text', readOnly: true, className: 'htCenter',}, 
  check: {type: 'text', readOnly: true, className: 'htCenter',}, 
  kigenDateReal: {type: 'text', readOnly: true, className: 'htCenter',}, 
  zaikoQtyBlReal: {type: 'text', readOnly: true, className: 'htCenter',}, 
};

export const rowDataType = {};

export const rowRendererName =
{
};

//列定義情報（動的パラメータ）
interface ZaikoKigenColRowModelInterface {
  //列固定
  fixedColumnsLeft: number,

  //テーブルの各列のキー
  colKeys: string[],
  //テーブルの各行のキー
  rowKeys: string[],
}

export class ZaikoKigenColRowModel implements ZaikoKigenColRowModelInterface {
  //列固定
  fixedColumnsLeft: number;
  //テーブルの各列のキー
  colKeys: string[];
  //テーブルの各列のキー
  rowKeys: string[];
  //ヘッダー
  nestedHeaders; //any[][]
  nestedHeadersBackup; //any[][]
  //ヘッダー区切り
  nestedHeadersGroupIndex: number[];
  //テーブルの各列の逆引き用マップ
  colKeysMap;
  //テーブルの各行の逆引き用マップ
  rowKeysMap;

  constructor(param: ZaikoKigenColRowModelInterface) {
    this.fixedColumnsLeft = param.fixedColumnsLeft;
    this.colKeys = param.colKeys;
    this.rowKeys = param.rowKeys;

    //テーブルの各列のキー逆引き
    const colKeysMap = {};
    param.colKeys.forEach((colKey, index) => {
      colKeysMap[colKey] = index;
    });
    this.colKeysMap = colKeysMap;
    //テーブルの各行のキー逆引き
    const rowKeysMap = {};
    param.rowKeys.forEach((rowKey, index) => {
      rowKeysMap[rowKey] = index;
    });
    this.rowKeysMap = rowKeysMap;

    //nestedHeaders初期化
    this.initNestedHeaders();
  }

  //nestedHeaders初期化
  initNestedHeaders() {
    //nestedHeaders
    const nestedHeaders = [];
    const nestedHeadersGroupIndex = [];
    // const customBorders = [];
    headersCol.forEach((header, index) => {
      const lastHeader = index == headersCol.length - 1;
      const nestedHeader = [];

      let colspan=0;
      let preHeaderVal = null;
      this.colKeys.forEach((colKey) => {
        const headerVal = header[colKey];
        if(lastHeader) {
          nestedHeader.push(headerVal);
        }
        else {
          if(preHeaderVal !== null && preHeaderVal != headerVal){
            nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
            colspan=0;
          }
          colspan++;
          preHeaderVal = headerVal;
        }
        //0行目でグループ
        if(index == 0) {
          nestedHeadersGroupIndex.push(colspan-1);
        }
      });
      if(!lastHeader && colspan > 0) {
        nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
      }

      nestedHeaders.push(nestedHeader);
    });

    this.nestedHeaders = nestedHeaders;
    this.nestedHeadersBackup = nestedHeaders.map(nestedHeadersSub => [...nestedHeadersSub]);
    this.nestedHeadersGroupIndex = nestedHeadersGroupIndex;
  }

  //function
  keyFromCol(column:number):string {
    return this.colKeys[column];
  }
  keyFromCols(columns:number[]):string[] {
    const vals:string[] = [];
    columns.forEach(column => vals.push(this.colKeys[column]))
    return vals;
  }
  colFromKey(colKey:string):number {
    return this.colKeysMap[colKey];
  }
  colFromKeys(colKeys:string[]):number[] {
    const vals:number[] = [];
    colKeys.forEach(colKey => vals.push(this.colKeysMap[colKey]))
    return vals;
  }
  //function
  keyFromRow(row:number):string {
    const rowKeys = this.rowKeys;
    return rowKeys[row];
  }
  keyFromRows(rows:number[]):string[] {
    const rowKeys = this.rowKeys;
    const vals:string[] = [];
    rows.forEach(row => vals.push(rowKeys[row]))
    return vals;
  }
  rowFromKey(rowKey:string):number {
    const rowKeysMap = this.rowKeysMap;
    return rowKeysMap[rowKey];
  }
  rowFromKeys(rowKeys:string[]):number[] {
    const rowKeysMap = this.rowKeysMap;
    const vals:number[] = [];
    rowKeys.forEach(rowKey => vals.push(rowKeysMap[rowKey]))
    return vals;
  }
}

//ZaikoKigen用の列モデル
export const zaikoKigenColRowModelDefault:ZaikoKigenColRowModel = new ZaikoKigenColRowModel({
  //列固定
  fixedColumnsLeft: headerColKeys.length + rowHeaderColKeys.length,

  //テーブルの各列のキー
  colKeys: [
    ...headerColKeys,
    ...rowHeaderColKeys,
    ...bodyColKeys,
  ],
  rowKeys: rowKeys,
});
